import { createSlice } from "@reduxjs/toolkit";

export const assistantSlice = createSlice ({
  name: 'assistant',
  initialState: [],
  reducers: {

  },
})

export default assistantSlice.reducer